import { Component, EventEmitter, Output } from '@angular/core';
import { UtilsService } from 'src/coreServices/utils.service';

const darkModeKey = 'dark-mode';

@Component({
  selector: 'geekeno-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss'],
})
export class ThemeSwitchComponent {
  isDarkModeEnabled = false;
  @Output() themeChange = new EventEmitter<boolean>();

  constructor(private utilService: UtilsService) {
    if (this.utilService.isPlatformServer) {
      return;
    }
    if (window.matchMedia && localStorage.getItem(darkModeKey) === null) {
      const query = window.matchMedia('(prefers-color-scheme: dark)');
      this.setPreferredTheme(query.matches);
    } else {
      this.setPreferredTheme(localStorage.getItem(darkModeKey) === 'true');
    }
    // since the output handlers are not attached yet
    setTimeout(() => this.themeChange.emit(this.isDarkModeEnabled));
  }

  toggleTheme() {
    this.setPreferredTheme(!this.isDarkModeEnabled);
  }

  private setPreferredTheme(prefersDarkMode: boolean) {
    if (prefersDarkMode) {
      this.switchToDarkMode();
    } else {
      this.switchToLightMode();
    }
    this.themeChange.emit(this.isDarkModeEnabled);
  }

  private switchToDarkMode() {
    this.isDarkModeEnabled = true;
    document.body.classList.add(darkModeKey);
    localStorage.setItem(darkModeKey, 'true');
  }

  private switchToLightMode() {
    this.isDarkModeEnabled = false;
    document.body.classList.remove(darkModeKey);
    localStorage.setItem(darkModeKey, 'false');
  }
}
