import { Component, OnInit } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharedData } from 'src/coreServices/shared-data.service';

@Component({
  selector: 'geekeno-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  icons = {
    email: faEnvelope,
    phone: faPhone,
    facebook: faFacebook,
    instagram: faInstagram,
    linkedin: faLinkedin,
    twitter: faTwitter,
    whatsApp: faWhatsapp,
  };
  readonly mapUrl: SafeResourceUrl;

  constructor(sanitizer: DomSanitizer, private sharedData: SharedData) {
    this.mapUrl = sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ6SMNrqzHvzsRZQ5X7MzavZU&key=${environment.mapsApiKey}`,
    );
  }

  ngOnInit(): void {
    this.sharedData.updateSiteTitle(
      'Different ways to get in touch with Geekeno',
    );
    this.sharedData.updateSiteDescription(
      'Get in touch with us today to get started with the digital growth of your business.',
    );
    this.sharedData.removeArticleTags();
  }
}
