<nav>
  <div class="nav-top">
    <div class="logo">
      <a [routerLink]="'/'">
        <img
          [src]="'assets/logo-' + (isDarkMode ? 'dark' : 'light') + '.svg'"
          alt="Geekeno Logo"
          width="300"
          height="106"
          loading="eager"
          title="Geekeno"
      /></a>
    </div>
    <button
      class="mobile-only"
      mat-icon-button
      (click)="onToggleNav()"
      aria-label="Navbar"
    >
      <fa-icon
        [icon]="menuButtonIcon"
        size="2x"
        [styles]="{ 'vertical-align': 'middle' }"
      ></fa-icon>
    </button>
  </div>
  <div
    class="navbar"
    [ngClass]="{ 'navbar--open': isNavbarOpen }"
    role="menubar"
  >
    <a
      class="nav-item"
      [routerLink]="'/'"
      [routerLinkActive]="['nav-item--active']"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeNavbar()"
      role="menuitem"
    >
      About Us
    </a>
    <button
      class="nav-item"
      [matMenuTriggerFor]="servicesMenu"
      [class.nav-item--active]="isServiceRouteLoaded && !isNavbarOpen"
      [disabled]="isNavbarOpen"
      role="menuitem"
    >
      Services
    </button>
    <a
      class="nav-item mobile-only"
      [routerLink]="'/services/product-development'"
      [routerLinkActive]="['nav-item--active']"
      (click)="closeNavbar()"
      role="menuitem"
      >&nbsp;&nbsp;Product Development</a
    >
    <a
      class="nav-item mobile-only"
      [routerLink]="'/services/digital-marketing'"
      [routerLinkActive]="['nav-item--active']"
      (click)="closeNavbar()"
      role="menuitem"
      >&nbsp;&nbsp;Digital Marketing</a
    >
    <a
      class="nav-item"
      [routerLink]="'/mission'"
      [routerLinkActive]="['nav-item--active']"
      (click)="closeNavbar()"
      role="menuitem"
    >
      Mission
    </a>
    <a
      class="nav-item"
      [routerLink]="'/contact-us'"
      [routerLinkActive]="['nav-item--active']"
      (click)="closeNavbar()"
      role="menuitem"
    >
      Contact Us
    </a>
  </div>
</nav>

<mat-menu
  #servicesMenu="matMenu"
  [class]="'nav-dropdown'"
  [xPosition]="'before'"
>
  <a
    class="nav-item"
    [routerLink]="'/services/product-development'"
    [routerLinkActive]="['nav-item--active']"
    role="menuitem"
    >Product Development</a
  >
  <a
    class="nav-item"
    [routerLink]="'/services/digital-marketing'"
    [routerLinkActive]="['nav-item--active']"
    role="menuitem"
    >Digital Marketing</a
  >
</mat-menu>
