import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/coreServices/api.service';

@Component({
  selector: 'geekeno-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Input() source: string = '';

  form: FormGroup;
  fields = [
    {
      name: 'first_name',
      label: 'First Name',
      validators: [Validators.required],
      type: 'text',
      autocomplete: 'given-name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
      validators: [],
      type: 'text',
      autocomplete: 'family-name',
    },
    {
      name: 'company',
      label: 'Company',
      validators: [],
      type: 'text',
      autocomplete: 'organization',
    },
    {
      name: 'email',
      label: 'Email',
      validators: [Validators.required, Validators.email],
      type: 'email',
      autocomplete: 'email',
    },
    {
      name: 'phone',
      label: 'Phone',
      validators: [Validators.required],
      type: 'tel',
      autocomplete: 'tel',
    },
    {
      name: 'requirements',
      label: 'Message',
      validators: [Validators.required],
      type: 'textarea',
      autocomplete: 'off',
    },
  ];

  submitting = false;
  submitted = false;
  showMessage = false;
  message = '';

  constructor(
    private apiService: ApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { source: string },
  ) {
    if (data) {
      this.source = data.source;
    }
    const controls: { [key: string]: FormControl } = {};
    this.fields.forEach((field) => {
      controls[field.name] = new FormControl('', field.validators);
    });
    controls['source'] = new FormControl(this.source, []);
    this.form = new FormGroup(controls);
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.apiService.post('/client/', this.form.value).subscribe({
      next: () => {
        this.submitted = true;
        this.submitting = false;
        setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      },
      error: () => {
        this.showMessage = true;
        this.message =
          'Something went wrong! Try again after some time or contact us in a different way.';
        this.submitting = false;
        setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      },
      complete: () => {
        this.submitting = false;
        this.showMessage = false;
      },
    });
  }

  ngOnInit() {
    this.form.controls['source'].setValue(this.source);
  }
}
