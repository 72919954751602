<form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="!submitted">
  <mat-form-field *ngFor="let field of fields">
    <mat-label>{{ field.label }}</mat-label>
    <ng-container [ngSwitch]="field.type">
      <textarea
        *ngSwitchCase="'textarea'"
        matInput
        [formControlName]="field.name"
        [type]="field.type"
      ></textarea>
      <input
        *ngSwitchDefault
        matInput
        [formControlName]="field.name"
        [type]="field.type"
        [autocomplete]="field.autocomplete"
      />
    </ng-container>
    <mat-error>
      <span *ngIf="form.controls[field.name].errors?.['required']">
        This field is required.
      </span>
      <span *ngIf="form.controls[field.name].errors?.['email']">
        Enter a valid email address.
      </span>
    </mat-error>
  </mat-form-field>
  <input formControlName="source" id="source" type="hidden" />

  <div class="form-row">
    <p
      style="text-align: center"
      [ngClass]="showMessage ? 'showMessage' : 'hideMessage'"
    >
      {{ message }}
    </p>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="form.invalid || submitting"
    >
      Submit
    </button>
  </div>
</form>
<div *ngIf="submitted" class="post-submission">
  <img
    src="assets/images/customers-service.svg"
    role="presentation"
    alt="request for quote received"
  />
  <div class="post-submission--message">
    <h2>Request Submitted Successfully</h2>
    <p>Someone from our team should get back to you soon.</p>
    <div class="spacer"></div>
    <div>
      <button mat-dialog-close mat-raised-button type="button">Close</button>
    </div>
  </div>
</div>
