<div class="container">
  <section class="first-section">
    <div
      class="digital-goa"
      style="background-image: url('assets/images/mission/mission-header.svg')"
    >
      <div class="mission-content">
        <div class="geekeno-mission">
          <div class="geekeno-logo">
            <img src="/assets/logo-round.svg" alt="geekeno logo" />
          </div>
          <h1>Geekeno's Mission</h1>
        </div>

        <h2>#DigitalGoa</h2>
      </div>
    </div>
  </section>

  <section class="section-1">
    <header>
      <h3>What does it mean to get Digital?</h3>
    </header>
    <div class="toget-digital">
      <div>
        <img
          src="assets/images/mission/sales-mechanism.svg"
          alt="sales icon"
          role="presentation"
        />
        <h4><strong>Aggressive Sales Mechanism</strong></h4>
        <p>
          Create digital channels for sales enquiries with tools to measure
          conversion & RoI.
        </p>
      </div>
      <div>
        <img
          src="assets/images/mission/branding.svg"
          alt="branding icon"
          role="presentation"
        />
        <h4><strong>Optimized Branding & Marketing</strong></h4>
        <p>
          Effective usage of digital tools to improve efficiency of marketing
          process and reach.
        </p>
      </div>
      <div>
        <img
          src="assets/images/mission/setting-framework.svg"
          alt="setting framework"
          role="presentation"
        />
        <h4><strong>Robust Operational Framework</strong></h4>
        <p>
          Leveraging simple digital tools to automate & streamline operations
          thereby save costs.
        </p>
      </div>
    </div>
  </section>

  <section class="section-2">
    <header>
      <h3>Why get Digital?</h3>
    </header>
    <div class="digital-content">
      <div class="info-box" *ngFor="let reason of reasonsToGetDigital">
        <p>
          {{ reason.prefix }} <strong>{{ reason.strongPoint }}</strong>
          {{ reason.suffix }}
        </p>
        <p class="info-box-source" *ngIf="reason.source">
          -{{ reason.source }}
        </p>
      </div>
    </div>
  </section>

  <section class="section-3">
    <header>
      <h3>Right way to get digital?</h3>
    </header>
    <div class="right-way-digital">
      <div class="right-way-box">
        <div class="right-way-circle circle-1">1</div>
        <div class="right-way-content content-box1">
          <p>Build high quality online presence with responsive website.</p>

          <p>Activate engaging social media presence</p>

          <p>Create positive brand image.and reputation.</p>
        </div>
      </div>

      <div class="right-way-box">
        <div class="right-way-circle circle-2">2</div>
        <div class="right-way-content content-box2">
          <p>Develop online channels for customer visibility.</p>

          <p>Activate lead generations systems.</p>

          <p>Track and analyze the sales achievement with tools</p>
        </div>
      </div>

      <div class="right-way-box">
        <div class="right-way-circle circle-3">3</div>
        <div class="right-way-content content-box3">
          <p>Improve efficiency of operations with simple tools.</p>

          <p>Manage your business data for easy accessibility</p>

          <p>Reduce costs & improve customer service with systems</p>
        </div>
      </div>
    </div>
  </section>

  <section class="section-4" id="packages">
    <header>
      <h3>Offer Packages</h3>
    </header>
    <div class="offer-packages">
      <div class="offer-package" *ngFor="let offerPackage of offerPackages">
        <div class="">
          <p>
            <strong>{{ offerPackage.title }}</strong>
          </p>
        </div>
        <button
          class="cost-overlay"
          mat-raised-button
          (click)="openContactForm(offerPackage.title)"
        >
          Request Quote
        </button>

        <div>
          <p *ngFor="let feature of offerPackage.webFeatures">{{ feature }}</p>
          <br />
          <p *ngFor="let feature of offerPackage.socialFeatures">
            {{ feature }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <p>
        # Subject to availability of desired domain. All packages include only 1
        domain for free. Premium domains, if selected, would cost extra.
      </p>
    </div>
  </section>
</div>

<geekeno-offer-banner
  source="Our Mission"
  buttonText="Request Quote"
  headerText="Get a Custom Quote"
  [hideHeader]="true"
  offerText="Get a Custom Quote"
  offerPromoText="Let us know your requirements and we shall get back to you with a quotation for the same"
></geekeno-offer-banner>
