import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
  selector: 'geekeno-offer-banner',
  templateUrl: './offer-banner.component.html',
  styleUrls: ['./offer-banner.component.scss'],
})
export class OfferBannerComponent {
  @Input() source = '';
  @Input() buttonText = 'Book Now';
  @Input() headerText = 'Launch Offer';
  @Input() hideHeader = false;
  @Input() offerText = 'Get your free digital audit';
  @Input() offerPromoText = 'Check your online presence quality';
  constructor(private dialog: MatDialog) {}

  openContactForm() {
    this.dialog.open(ContactFormComponent, { data: { source: this.source } });
  }
}
