<section>
  <header><h1>Terms and Conditions</h1></header>
  <p>Welcome to Geekeno!</p>

  <p>
    These terms and conditions outline the rules and regulations for the use of
    Geekeno's Website, located at
    <a href="https://geekeno.com/">https://geekeno.com/</a>.
  </p>

  <p>
    By accessing this website we assume you accept these terms and conditions.
    Do not continue to use Geekeno if you do not agree to take all of the terms
    and conditions stated on this page.
  </p>

  <p>
    The following terminology applies to these Terms and Conditions, Privacy
    Statement and Disclaimer Notice and all Agreements: "Client", "You" and
    "Your" refers to you, the person accessing this website and compliant to the
    Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and
    "Us", refers to our Company, Geekeno. "Party", "Parties", or "Us", refers to
    both the Client and ourselves. All terms refer to the offer, acceptance and
    consideration of payment necessary to undertake the process of our
    assistance to the Client in the most appropriate manner for the express
    purpose of meeting the Client’s needs in respect of provision of the
    Company’s stated services, in accordance with and subject to, prevailing law
    of India. Any use of the above terminology or other words in the singular,
    plural, capitalization and/or he/she or they, are taken as interchangeable
    and therefore as referring to same.
  </p>
</section>

<section>
  <header><h2>Cookies</h2></header>
  <p>
    We employ the use of cookies. By accessing Geekeno, you agreed to use
    cookies in agreement with the Geekeno's
    <a routerLink="/privacy-policy">Privacy Policy</a>.
  </p>

  <p>
    Most interactive websites use cookies to let us retrieve the user’s details
    for each visit. Cookies are used by our website to enable the functionality
    of certain areas to make it easier for people visiting our website. Some of
    our affiliate partners may also use cookies.
  </p>
</section>

<section>
  <header><h2>License</h2></header>
  <p>
    Unless otherwise stated, Geekeno and/or its licensors own the intellectual
    property rights for all material on Geekeno. All intellectual property
    rights are reserved. You may access this from Geekeno for your own personal
    use subjected to restrictions set in these terms and conditions.
  </p>

  <p>You must not:</p>
  <ul>
    <li>Republish material from Geekeno</li>
    <li>Sell, rent or sub-license material from Geekeno</li>
    <li>Reproduce, duplicate or copy material from Geekeno</li>
    <li>Redistribute content from Geekeno</li>
  </ul>
</section>

<section>
  <header><h2>Hyperlinking to our Content</h2></header>
  <p>
    Anyone may link to our home page, to publications or to other Website
    information so long as the link:
  </p>
  <ol>
    <li>is not in any way deceptive;</li>
    <li>
      does not falsely imply sponsorship, endorsement or approval of the linking
      party and its products and/or services;
    </li>
    <li>
      the link would not make us look unfavorably to ourselves or to our
      accredited businesses;
    </li>
    <li>the organization does not have any negative records with us;</li>
    <li>
      the benefit to us from the visibility of the hyperlink compensates the
      absence of Geekeno; and
    </li>
    <li>fits within the context of the linking party’s site;</li>
  </ol>

  <p>Organizations may hyperlink to our Website as follows:</p>
  <ul>
    <li>By use of our corporate name; or</li>
    <li>By use of the uniform resource locator being linked to; or</li>
    <li>
      By use of any other description of our Website being linked to that makes
      sense within the context and format of content on the linking party’s
      site.
    </li>
  </ul>

  <p>
    No use of Geekeno's logo or other artwork will be allowed for linking to a
    non Geekeno URL.
  </p>
</section>

<section>
  <header><h2>iFrames</h2></header>
  <p>
    Without prior approval and written permission, you may not create frames
    around our Webpages that alter in any way the visual presentation or
    appearance of our Website.
  </p>
</section>

<section>
  <header><h2>Your Privacy</h2></header>
  <p>Please read <a routerLink="/privacy-policy">Privacy Policy</a>.</p>
</section>

<section>
  <header><h2>Reservation of Rights</h2></header>
  <p>
    We reserve the right to request that you remove all links or any particular
    link to our Website. You approve to immediately remove all links to our
    Website upon request. We also reserve the right to amend these terms and
    conditions and it’s linking policy at any time. By continuously linking to
    our Website, you agree to be bound to and follow these linking terms and
    conditions.
  </p>
</section>

<section>
  <header><h2>Removal of links from our website</h2></header>
  <p>
    If you find any link on our Website that is offensive for any reason, you
    are free to contact and inform us. We will consider requests to remove links
    but we are not obligated to or so or to respond to you directly.
  </p>

  <p>
    We do not ensure that the information on this website is correct, we do not
    warrant its completeness or accuracy; nor do we promise to ensure that the
    website remains available or that the material on the website is kept up to
    date.
  </p>
</section>

<section>
  <header><h2>Disclaimer</h2></header>
  <p>
    To the maximum extent permitted by applicable law, we exclude all
    representations, warranties and conditions relating to our website and the
    use of this website. Nothing in this disclaimer will:
  </p>

  <ul>
    <li>
      limit or exclude our or your liability for death or personal injury;
    </li>
    <li>
      limit or exclude our or your liability for fraud or fraudulent
      misrepresentation;
    </li>
    <li>
      limit any of our or your liabilities in any way that is not permitted
      under applicable law; or
    </li>
    <li>
      exclude any of our or your liabilities that may not be excluded under
      applicable law.
    </li>
  </ul>

  <p>
    The limitations and prohibitions of liability set in this Section and
    elsewhere in this disclaimer:
  </p>
  <ol>
    <li>are subject to the preceding paragraph; and</li>
    <li>
      govern all liabilities arising under the disclaimer, including liabilities
      arising in contract, in tort and for breach of statutory duty.
    </li>
  </ol>

  <p>
    As long as the website and the information and services on the website are
    provided free of charge, we will not be liable for any loss or damage of any
    nature.
  </p>
</section>
