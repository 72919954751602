import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class SharedData {
  public siteTitle: Observable<string>;
  private readonly descriptionMaxLength = 150;
  private siteTitleSubject: BehaviorSubject<string>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private title: Title,
    private utilService: UtilsService,
  ) {
    this.siteTitleSubject = new BehaviorSubject<string>(this.title.getTitle());
    this.siteTitle = this.siteTitleSubject.asObservable();
  }

  updateSiteTitle(title: string) {
    this.title.setTitle(title);
    if (title && title.length) {
      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.meta.updateTag({ name: 'twitter:image:alt', content: title });
      this.meta.updateTag({ property: 'og:image:alt', content: title });
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ name: 'title', content: title });
    } else {
      this.meta.removeTag(`name='twitter:title'`);
      this.meta.removeTag(`name='twitter:image:alt'`);
      this.meta.removeTag(`property='og:image:alt'`);
      this.meta.removeTag(`property='og:title'`);
      this.meta.removeTag(`name='title'`);
    }
    this.siteTitleSubject.next(title);
    if (this.utilService.isPlatformBrowser) {
      this.document.title = title;
    }
  }

  updateSiteDescription(description: string) {
    description = this.utilService.truncate(
      this.utilService.stripHtml(description),
      this.descriptionMaxLength,
      true,
      true,
    );
    if (description && description.length) {
      this.meta.updateTag({
        name: 'twitter:description',
        content: description,
      });
      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({ name: 'description', content: description });
    } else {
      this.meta.removeTag(`name='twitter:description'`);
      this.meta.removeTag(`property='og:description'`);
      this.meta.removeTag(`name='description'`);
    }
  }

  updateSiteImage(imageUrl: string) {
    if (!imageUrl) {
      imageUrl = '/assets/geekeno-og-image.png';
    }
    imageUrl = `https://geekeno.com${imageUrl}`;
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.updateTag({
      name: 'twitter:site',
      content: '@geekeno',
    });
  }

  updatePageUrl(url: string) {
    if (url && url.length) {
      url = `https://geekeno.com${url}`;
      this.meta.updateTag({ property: 'og:url', content: url });
      this.meta.updateTag({ name: 'twitter:url', content: url });
    } else {
      this.meta.removeTag(`property='og:url'`);
      this.meta.removeTag(`name='twitter:url'`);
    }
  }

  updateCanonicalUrl(url: string) {
    url = `https://geekeno.com${url}`;
    let link = this.document.querySelector('link[rel="canonical"]');
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
    }
    link.setAttribute('href', url);
  }

  setPageAsArticle(author: string, timestamp: string) {
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'article:author', content: author });
    this.meta.updateTag({
      property: 'article:published_time',
      content: timestamp,
    });
  }

  removeArticleTags() {
    this.meta.updateTag({ property: 'og:type', content: 'website' }); // reset to default
    this.meta.removeTag(`property='article:author'`);
    this.meta.removeTag(`property='article:published_time'`);
  }
}
