<geekeno-cookie-message *ngIf="cookieNotAccepted"></geekeno-cookie-message>
<header>
  <geekeno-navbar [isDarkMode]="isDarkMode"></geekeno-navbar>
</header>
<main class="main-content">
  <router-outlet></router-outlet>
  <geekeno-theme-switch
    (themeChange)="this.isDarkMode = $event.valueOf()"
  ></geekeno-theme-switch>
</main>

<footer>
  <div class="social-media-icons">
    <div>Follow us on</div>
    <div>
      <a
        href="https://fb.me/geekeno"
        mat-icon-button
        target="_blank"
        rel="noopener"
        aria-label="Visit Facebook page"
      >
        <fa-icon [icon]="icons.facebook"></fa-icon>
      </a>
    </div>
    <div>
      <a
        href="https://www.instagram.com/geek.eno/"
        mat-icon-button
        target="_blank"
        rel="noopener"
        aria-label="View Instagram profile"
      >
        <fa-icon [icon]="icons.instagram"></fa-icon>
      </a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/company/geekeno/"
        mat-icon-button
        target="_blank"
        rel="noopener"
        aria-label="View LinkedIn page"
      >
        <fa-icon [icon]="icons.linkedin"></fa-icon>
      </a>
    </div>
    <div>
      <a
        href="https://www.twitter.com/geekeno/"
        mat-icon-button
        target="_blank"
        rel="noopener"
        aria-label="View Twitter profile"
      >
        <fa-icon [icon]="icons.twitter"></fa-icon>
      </a>
    </div>
  </div>
  <div class="copyright">©{{ currentYear }} Geekeno. All rights reserved</div>
</footer>
<div class="new-version-notification" *ngIf="isNewVersionAvailable">
  <p>A newer version of Geekeno is available.</p>
  <button mat-raised-button (click)="forceReload()">Reload Now</button>
</div>
