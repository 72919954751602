<div>
  <p>
    <fa-icon [icon]="exclamationIcon"></fa-icon>
    This site uses cookies for analytics and personalization. By continuing to
    browse this site, you agree to this use.
  </p>
  <small>
    <a routerLink="/privacy-policy">Read our Privacy Policy to learn more</a>
  </small>
</div>
