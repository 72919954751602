import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UtilsService } from 'src/coreServices/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private utilService: UtilsService) {}

  get(
    path: string,
    params: string | Record<string, any> = {},
    otherHeaders?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    },
  ): Observable<any> {
    const args =
      typeof params === 'string'
        ? { fromString: String(params) }
        : { fromObject: Object(params) };
    const httpParams = new HttpParams(args);
    const options = { params: httpParams, ...otherHeaders };
    const url = this.buildUrl(path);
    return this.http.get(url, options);
  }

  private buildUrl(path: string) {
    if (this.utilService.isPlatformBrowser) {
      return `${environment.apiUrl}${path}`;
    }
    return `${environment.backendUrl}${environment.apiUrl}${path}`;
  }

  put(
    path: string,
    body: Record<string, any> | FormData = {},
  ): Observable<any> {
    const url = this.buildUrl(path);
    return this.http.put(url, body);
  }

  post(
    path: string,
    body: Record<string, any> | FormData = {},
    options?: any,
  ): Observable<any> {
    const url = this.buildUrl(path);
    return this.http.post(url, body, options);
  }

  patch(
    path: string,
    body: Record<string, any> | FormData = {},
  ): Observable<any> {
    const url = this.buildUrl(path);
    return this.http.patch(url, body);
  }

  delete(path: string): Observable<any> {
    const url = this.buildUrl(path);
    return this.http.delete(url);
  }
}
