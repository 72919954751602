<mat-card>
  <mat-card-title>
    <h1>404 - Page not found.</h1>
  </mat-card-title>

  <mat-card-content>
    <picture>
      <img
        src="/assets/images/full body on light.svg"
        class="info-card--image"
        alt="page not found"
        loading="lazy"
      />
    </picture>
    <p>Looks like you've stumbled upon a non existent page.</p>
    <p>
      Check the URL for typos or get in touch with the person who sent you the
      url.
    </p>
  </mat-card-content>
</mat-card>
