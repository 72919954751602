import { Component, Input } from '@angular/core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'geekeno-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  //used for mobile navbar
  isNavbarOpen = false;
  menuButtonIcon = faBars;
  @Input() isDarkMode = false;
  isServiceRouteLoaded = false;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((evt) => evt instanceof NavigationEnd))
      .subscribe((evt) => {
        this.isServiceRouteLoaded = this.router.isActive('/services', {
          paths: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored',
          queryParams: 'ignored',
        });
      });
  }

  onToggleNav() {
    if (this.isNavbarOpen) {
      this.closeNavbar();
    } else {
      this.openNavbar();
    }
  }

  closeNavbar() {
    this.isNavbarOpen = false;
    this.menuButtonIcon = faBars;
  }

  private openNavbar() {
    this.isNavbarOpen = true;
    this.menuButtonIcon = faTimes;
  }
}
