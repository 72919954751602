import {
  ApplicationRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { concat, filter, first, interval, Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SharedData } from '../coreServices/shared-data.service';
import { WindowService } from '../coreServices/window.service';
import { UtilsService } from 'src/coreServices/utils.service';
import { JsonLdService } from 'ngx-seo';

@Component({
  selector: 'geekeno-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly currentYear = new Date().getFullYear();

  icons = {
    facebook: faFacebook,
    instagram: faInstagram,
    linkedin: faLinkedin,
    twitter: faTwitter,
    whatsApp: faWhatsapp,
  };
  isDarkMode = false;
  private readonly timeInMillisecondsBetweenChecks = 6 * 60 * 60 * 1000; // 6 hours
  isNewVersionAvailable = false;
  private subscriptions: Subscription[] = [];
  cookieNotAccepted = false;
  private previousUrlWithoutQueryParams = '';

  constructor(
    private appRef: ApplicationRef,
    private jsonLdService: JsonLdService,
    private router: Router,
    private sharedData: SharedData,
    private utilsService: UtilsService,
    private update: SwUpdate,
    private windowService: WindowService,
  ) {
    this.update.versionUpdates.subscribe(this.onVersionUpdate.bind(this));
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable),
    );
    const everySixHours$ = interval(this.timeInMillisecondsBetweenChecks);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    if (this.update.isEnabled) {
      everySixHoursOnceAppIsStable$.subscribe(() =>
        this.update.checkForUpdate(),
      );
    }
  }

  ngOnInit() {
    //set default image
    this.sharedData.updateSiteImage('/assets/geekeno-og-image.png');
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe({
          next: () => {
            this.jsonLdService.setData([]);
          },
        }),
      this.router.events
        .pipe(filter((event2) => event2 instanceof NavigationEnd))
        .subscribe({
          next: (event2) => {
            const urlWithoutQueryParams = this.router.url.split('?')[0];
            this.sharedData.updatePageUrl(this.router.url);
            if (this.previousUrlWithoutQueryParams != urlWithoutQueryParams) {
              this.sharedData.updateCanonicalUrl(urlWithoutQueryParams);
              if (!this.router.getCurrentNavigation()?.extractedUrl.fragment) {
                this.windowService.scroll({ top: 0, left: 0 });
              }
              this.previousUrlWithoutQueryParams = urlWithoutQueryParams;
            }
          },
        }),
    );
    if (this.utilsService.isPlatformBrowser) {
      this.cookieNotAccepted =
        localStorage.getItem('hasAcceptedCookies') !== 'true';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  private onVersionUpdate(evt: VersionEvent) {
    switch (evt.type) {
      case 'VERSION_DETECTED':
        console.log(`Downloading new app version: ${evt.version.hash}`);
        break;
      case 'VERSION_READY':
        console.log(`Current app version: ${evt.currentVersion.hash}`);
        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        this.isNewVersionAvailable = true;
        break;
      case 'VERSION_INSTALLATION_FAILED':
        console.log(
          `Failed to install app version '${evt.version.hash}': ${evt.error}`,
        );
        break;
    }
  }

  forceReload() {
    this.update.activateUpdate().then(() => {
      this.windowService.reload();
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent) {
    if (
      this.cookieNotAccepted &&
      event
        .composedPath()
        .every(
          (element) =>
            (element as Element).tagName !== 'GEEKENO-COOKIE-MESSAGE',
        )
    ) {
      this.cookieNotAccepted = false;
      localStorage.setItem('hasAcceptedCookies', 'true');
    }
  }
}
