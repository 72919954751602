<section class="section-intro active">
  <h1>We Build Awesome <span id="typedText"></span></h1>
  <div id="stringsToType">
    <span>Products</span>
    <span>Websites</span>
    <span>Apps</span>
    <span>Brands</span>
  </div>
  <img
    src="assets/images/services-offered.svg"
    alt="services offered: digital marketing, website development, mobile app development"
    loading="eager"
    width="267"
    height="150"
  />
</section>

<section>
  <header><h2>Geekeno</h2></header>
  <p>
    /gi:keno/
    <button
      mat-icon-button
      aria-label="Learn to Pronounce Geekeno"
      (click)="pronunciation.play()"
    >
      <fa-icon [icon]="volumeUp"></fa-icon>
    </button>
    <audio #pronunciation src="assets/audio/pronunciation.mp3"></audio>
  </p>
  <p><em>noun:</em> team of awesome tech experts</p>
  <p>
    {{ whoWeAre }}
  </p>
  <div class="about-us">
    <div>
      <img
        src="assets/images/geekeno-creative-mindset.svg"
        alt="Geekeno's creative mindset"
        width="810"
        height="810"
        loading="lazy"
      />
    </div>
    <div>
      <p>
        We are neither yet another website, app, or software development
        company, nor are we just a digital marketing agency.
      </p>
      <strong>Our Mission</strong>
      <ul>
        <li>
          Empower every small & medium business in Goa with the right knowledge
          about digitization.
        </li>
        <li>
          Transform the digital presence & tech stack of every small & medium
          business in Goa.
        </li>
        <li>
          Help every small & medium business increase revenue and decrease
          costs.
        </li>
      </ul>
      <strong>Our Vision</strong>
      <ul>
        <li>We aim to be your extended team, whom you can fully rely on.</li>
        <li>
          We aim not just to code a website, app or software but, to be a part
          in achieving your ultimate goal!
        </li>
        <li>
          We aim not just to execute some digital marketing activities but,
          bring marketing strategy & results.
        </li>
      </ul>
    </div>
  </div>
</section>

<section>
  <header><h2>Why Us?</h2></header>
  <div class="why-us">
    <div class="why-us-reason">
      <img
        src="assets/images/value-for-money.svg"
        alt="Value for Money"
        loading="lazy"
        width="150"
        height="150"
      />
      <strong>Value for Money</strong>
    </div>
    <div class="why-us-reason">
      <img
        src="assets/images/customers-service.svg"
        alt="Quality Customer Service"
        loading="lazy"
        width="150"
        height="150"
      />
      <strong>Quality Customer Service</strong>
    </div>
    <div class="why-us-reason">
      <img
        src="assets/images/turnaround-time.svg"
        alt="SLA Bound Delivery"
        loading="lazy"
        width="150"
        height="150"
      />
      <strong>SLA Bound Delivery</strong>
    </div>
  </div>
</section>

<section>
  <header><h2>Services</h2></header>
  <div class="services">
    <p class="services-description">
      We bring to the table a blend of creative thinking, business strategic
      mindset & conviction to be your extended team in achieving your project
      goals, thereby we deliver High quality product development & digital
      marketing services by utilizing the latest tech, tools, and trends.
    </p>
    <div class="services-service-block">
      <fa-icon [icon]="laptop" size="4x"></fa-icon>
      <h3>Product Development</h3>
      <p>
        Website Development, Mobile App Development, Software Development, UX/UI
        Design and other related services.
      </p>
      <a href="/services/product-development">View details</a>
    </div>
    <div class="services-service-block">
      <fa-icon [icon]="advertising" size="4x"></fa-icon>
      <h3>Digital Marketing</h3>
      <p>
        Marketing Strategy, Search Engine Optimization (SEO), Social Media
        Optimization (SMO), PPC Ads and other such solutions.
      </p>
      <a href="/services/digital-marketing">View details</a>
    </div>
  </div>
</section>

<geekeno-offer-banner source="About Us"></geekeno-offer-banner>
