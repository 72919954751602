import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(@Inject(PLATFORM_ID) private platformId: number) {}

  /**
   * Converts a JavaScript Object Notation (JSON) string into an object.
   *
   * @param jsonString A valid JSON string.
   * @param propertiesToRename A mapping of old property names to new property names.
   */
  static toJsonObject(
    jsonString: Record<string, any> | string,
    propertiesToRename: { [s: string]: string } = {},
  ): Record<string, any> {
    const jsonObject =
      typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
    if (jsonObject) {
      for (const oldName in propertiesToRename) {
        if (oldName in jsonObject) {
          jsonObject[propertiesToRename[oldName]] = jsonObject[oldName];
          delete jsonObject[oldName];
        }
      }
    }
    return jsonObject;
  }

  // extract the error message from json error objects
  // {field: {0: "this is the error message"}
  // {field: {2: ["this is the error message"]}}
  getErrorStringFromApiResponse(
    errorResponse: any,
    genericMessage = 'Something went wrong',
  ) {
    const responseKeys = Object.keys(errorResponse);

    if (!responseKeys.length) {
      return genericMessage;
    }

    const error = errorResponse[responseKeys[0]];
    if (typeof error === 'string') {
      return error || genericMessage;
    }

    const errorKeys = Object.keys(error);
    return errorKeys.length
      ? error[errorKeys[0]] || genericMessage
      : genericMessage;
  }

  get isPlatformBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  get isPlatformServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * `wait` milliseconds.
   * https://gist.github.com/treyhuffine/2ced8b8c503e5246e2fd258ddbd21b8c
   */
  debounce(func: (...args: any[]) => void, wait: number = 250) {
    let timeout: number;

    return (...args: any[]) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = window.setTimeout(later, wait);
    };
  }

  /**
   * Returns a copy of the given `html` string with all HTML tags removed.
   */
  stripHtml(html: string) {
    return html.replace(/<[^>]*>?/gm, '');
  }

  /**
   * Returns a copy of the given string `str` truncated to `n` characters.
   * If `useWordBoundary = true`, the it is truncated to the last word.
   * If `useEllipses = true`, then the string is suffixed with horizontal ellipses (…)
   */
  truncate(
    str: string,
    n: number,
    useWordBoundary: boolean = false,
    useEllipses: boolean = false,
  ) {
    if (str.length <= n) {
      return str;
    }
    const subString = str.substring(0, n);
    return (
      (useWordBoundary
        ? subString.substring(0, subString.lastIndexOf(' '))
        : subString) + (useEllipses ? '…' : '')
    );
  }
}
