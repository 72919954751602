<div class="content">
  <h1>
    We ❤️ for you to start a conversation&hellip; and there’s more than one way
    to get started 😉
  </h1>
  <div class="actual-content">
    <section>
      <geekeno-contact-form
        class="contact-form"
        source="Contact Form"
      ></geekeno-contact-form>
    </section>
    <section>
      <div class="contact-info">
        <a href="tel:+919823994536" target="_blank" rel="noopener">
          <fa-icon [icon]="icons.phone"></fa-icon>
          +91 98239 94536</a
        >
        <a href="mailto:info@geekeno.com" target="_blank" rel="noopener">
          <fa-icon [icon]="icons.email"></fa-icon>
          info@geekeno.com</a
        >
        <a href="https://wa.me/919823994536" target="_blank" rel="noopener">
          <fa-icon [icon]="icons.whatsApp"></fa-icon>
          +91 98239 94536</a
        >
      </div>
      <p></p>
      <p>or message us via your favourite social media channel</p>
      <div class="social-info">
        <a href="https://fb.me/geekeno" target="_blank" rel="noopener">
          <fa-icon [icon]="icons.facebook"></fa-icon>
          geekeno</a
        >
        <a
          href="https://www.linkedin.com/company/geekeno/"
          target="_blank"
          rel="noopener"
        >
          <fa-icon [icon]="icons.linkedin"></fa-icon>
          geekeno</a
        >
        <a
          href="https://www.instagram.com/geek.eno/"
          target="_blank"
          rel="noopener"
        >
          <fa-icon [icon]="icons.instagram"></fa-icon>
          geek.eno</a
        >
        <a href="https://twitter.com/geekeno" target="_blank" rel="noopener">
          <fa-icon [icon]="icons.twitter"></fa-icon>
          geekeno</a
        >
      </div>
    </section>
    <section>
      <div class="address">
        <p></p>
        <p>Or drop in for a visit</p>
        <div class="address--text">
          <a
            target="_blank"
            href="https://maps.google.com/maps?ll=15.389216,73.818176&amp;z=15&amp;t=m&amp;hl=en-GB&amp;gl=US&amp;mapclient=embed&amp;cid=10790020855922888293"
          >
            First Floor, Le Chateau,<br />Vasco-da-Gama, Goa 403802
          </a>
        </div>
        <iframe
          class="address--map"
          width="300"
          height="225"
          style="border: 0"
          loading="lazy"
          allowfullscreen
          title="Geekeno Address"
          [src]="mapUrl"
        ></iframe>
      </div>
    </section>
  </div>
</div>
